<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col
        cols="12"
        lg="12"
        md="6"
      >
        <analytics-congratulation :data="userData" />
      </b-col>
      <b-col
        cols="12"
        lg="12"
        md="6"
      >
        <b-card title="Send custom Notify">
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="`User`"
                label-for="user_id"
                :state="errors && errors.user_id ? false : null"
              >
                <v-select
                  id="user_id"
                  v-model="item.user_id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="userOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="user_id"
                  autocomplete="off"
                  :searchable="true"
                  @search="searchUser"
                />
                <b-form-invalid-feedback v-if="errors && errors.user_id">
                  {{ errors.user_id[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="`Text`"
                label-for="description"
                :state="errors && errors.description ? false : null"
              >
                <b-form-textarea
                  id="description"
                  v-model="item.description"
                  :state="errors && errors.description ? false : null"
                />
                <b-form-invalid-feedback v-if="errors && errors.description">
                  {{ errors.description[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="onSubmit"
              >
                Send
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import GlobalMixin from '@/mixins/GlobalMixin'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'

export default {
  components: {
    AnalyticsCongratulation,
  },
  mixins: [GlobalMixin],
  data() {
    return {
      item: {
        mass: 'one',
        user_id: 0,
        description: null,
      },
      massItems: [
        { title: 'All admins', value: 'admins' },
        { title: 'All clients', value: 'clients' },
        { title: 'One User', value: 'one' },
      ],
      userOptions: [],
      userData: JSON.parse(localStorage.getItem(useJwt.jwtConfig.userDataKey)),
    }
  },
  async beforeCreate() {
    await this.$http.get('/api/users')
      .then(response => {
        this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
      })
  },
  methods: {
    async onSubmit() {
      this.$store.dispatch('validation/clearErrors')
      this.$http.post('/api/admin/notifications/custom', { ...this.item })
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Success!',
              icon: 'AlertCircleIcon',
              variant: 'success',
            },
          })
          this.item = {
            mass: 'one',
            user_id: 0,
            description: null,
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: error.response.data.message,
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
        })
    },
    async searchUser(query) {
      await this.$http.get('/api/users', {
        params: {
          query,
        },
      })
        .then(response => {
          this.userOptions = this.$options.filters.transformForVSelect(response.data.data, 'id', 'name')
        })
    },
  },
}
</script>
